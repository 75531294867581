import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { api, apiToken } from "../../config";
import "./pay-status-style.scss";
import { db } from "../../firebase/firebaseConfig";
import { doc, updateDoc, increment } from "firebase/firestore";
import { AppContext } from "../../contexts/AppContext";

const TicketPaySuccess = (props) => {
  const [store, setStore] = useContext(AppContext);
  const { siteSettings } = store;
  const ticketId = props.match.params.ticketId;
  const transactionId = props.match.params.transactionId;
  const memId = props.match.params.memId;
  const [loading, setLoading] = useState(true);
  const updateStatus = async () => {
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          "Cockpit-Token": apiToken,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          data: {
            _id: transactionId,
            status: "success",
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();

    // get ticket data
    const bookingData = await fetch(`${api}/collections/get/tickets`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        filter: { _id: ticketId },
      }),
    });
    const ticketData = await bookingData.json();
    const bookedData = ticketData.entries[0];
    const ticketCount =
      parseInt(bookedData.numberOfAdults) +
      parseInt(bookedData.numberOfChildrenAbove5) +
      parseInt(bookedData.numberOfChildrenAbove13) +
      parseInt(bookedData.numberOfChildrenBelow5) +
      parseInt(bookedData.numberOfParents);
    try {
      // update ticket count
      const eventDoc = doc(db, "bookings", siteSettings.firebaseEventId);
      const res = await updateDoc(eventDoc, {
        bookedTickets: increment(ticketCount),
      });
      console.log("Document successfully updated!");
    } catch (e) {
      console.error("Error updating document: ", e);
    }

    const memData = await fetch(`${api}/collections/save/tickets`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        data: {
          _id: ticketId,
          paymentStatus: true,
          status: true,
        },
      }),
    });
    const members = await memData.json();
    setLoading(false);
  };

  useEffect(() => {
    if (siteSettings) {
      updateStatus();
    }
  }, [siteSettings]);

  return (
    <div className="wrapper success-page">
      <h2>Payment Success</h2>
      <p>You can see the ticket in My Tickets page.</p>
      <Link to="/mem-account" className="btn" disabled={loading}>
        {loading ? "Loading..." : "Done"}
      </Link>
    </div>
  );
};

export default TicketPaySuccess;
