import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Redirect } from "react-router-dom";
import AdminMenu from "./AdminMenu";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { api, apiToken } from "../../config";

import "./style.css";
import TicketWrapper from "./TicketWrapper";

const TicketLists = (props) => {
  const [store, setStore] = useContext(AppContext);
  const [tickets, setTickets] = useState(null);
  const { auth } = store;
  const initialState = {
    event: "",
    year: new Date().getFullYear(),
  };
  const [value, setValue] = useState(initialState);
  const changeHandler = (e) => {
    if (e.target.name === "year") {
      setValue({ ...value, event: "", year: e.target.value });
    } else {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  const getTicketList = async () => {
    const data = await fetch(`${api}/collections/get/tickets`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { transactionTitle: -1 },
        filter: {
          year: value.year,
          paymentStatus: true,
        },
      }),
    });
    const ticketData = await data.json();
    const ticketByTitle = [];
    ticketData.entries.forEach((element) => {
      if (ticketByTitle[element.title]) {
        ticketByTitle[element.title].entries.push(element);
        ticketByTitle[element.title].adultsCount += parseInt(
          element.numberOfAdults
        );
        ticketByTitle[element.title].parentsCount += parseInt(
          element.numberOfParents
        );
        ticketByTitle[element.title].above13Count += parseInt(
          element.numberOfChildrenAbove13
        );
        ticketByTitle[element.title].above5Count += parseInt(
          element.numberOfChildrenAbove5
        );
        ticketByTitle[element.title].below5Count += parseInt(
          element.numberOfChildrenBelow5
        );
        ticketByTitle[element.title].totalPrice += parseFloat(
          element.ticketPrice
        );
      } else {
        ticketByTitle[element.title] = {
          entries: new Array(element),
          adultsCount: parseInt(element.numberOfAdults),
          parentsCount: parseInt(element.numberOfParents),
          above13Count: parseInt(element.numberOfChildrenAbove13),
          above5Count: parseInt(element.numberOfChildrenAbove5),
          below5Count: parseInt(element.numberOfChildrenBelow5),
          totalPrice: parseFloat(element.ticketPrice),
        };
      }
    });
    const eventArray = Object.keys(ticketByTitle);
    setValue((prev) => ({ ...prev, event: eventArray[eventArray.length - 1] }));

    setTickets(ticketByTitle);
  };

  const totalCount = (item) => {
    const totalCnt =
      item.adultsCount +
      item.parentsCount +
      item.above13Count +
      item.above5Count +
      item.below5Count;
    return totalCnt;
  };

  useEffect(() => {
    getTicketList();
  }, [value.year]);

  return (
    <div className="tp-case-details-area section-padding">
      <div className="container-fluid">
        <div className="row">
          {auth && (
            <div className="col col-12">
              {auth.role === "accountant" ? (
                <div className="tp-blog-sidebar">
                  <AdminMenu />
                  <br />
                  <br />
                  <div className="widget search-widget">
                    <div className="row">
                      <div className="col col-6 col-md-6">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="year-label">year</InputLabel>
                          <Select
                            className="inputOutline"
                            name="year"
                            value={value[`year`]}
                            id="year-label"
                            label="year"
                            placeholder="year"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          >
                            <MenuItem value={new Date().getFullYear()}>
                              {new Date().getFullYear()}
                            </MenuItem>
                            <MenuItem value={new Date().getFullYear() - 1}>
                              {new Date().getFullYear() - 1}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col col-6 col-md-6">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="event-label">Event</InputLabel>
                          <Select
                            className="inputOutline"
                            name="event"
                            value={value[`event`]}
                            id="event-label"
                            label="event"
                            placeholder="event"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          >
                            <MenuItem value="">Please Select</MenuItem>
                            {tickets &&
                              Object.keys(tickets).map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row peopleCountWrapper">
                      <div className="col col-12 col-md-12">
                        <div className="pCountItem pink">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].adultsCount}
                            </h4>
                            <span>Adults</span>
                          </div>
                        </div>
                        <div className="pCountItem">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].parentsCount}
                            </h4>
                            <span>Parents</span>
                          </div>
                        </div>
                        <div className="pCountItem purple">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].above13Count}
                            </h4>
                            <span>Above 13</span>
                          </div>
                        </div>
                        <div className="pCountItem blue">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].above5Count}
                            </h4>
                            <span>Above 5</span>
                          </div>
                        </div>
                        <div className="pCountItem green">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].below5Count}
                            </h4>
                            <span>Below 5</span>
                          </div>
                        </div>
                        <div className="pCountItem teal">
                          <div>
                            <h4>
                              {tickets &&
                                tickets[value.event] &&
                                totalCount(tickets[value.event])}
                            </h4>
                            <span>Total Count</span>
                          </div>
                        </div>
                        <div className="pCountItem orange">
                          <div>
                            <h4>
                              £{` `}
                              {tickets &&
                                tickets[value.event] &&
                                tickets[value.event].totalPrice}
                            </h4>
                            <span>Total Amount</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {tickets && (
                    <TicketWrapper search={value} tickets={tickets} />
                  )}
                </div>
              ) : (
                <Redirect to="/mem-account" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketLists;
